/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ClearlinkTeam from '../images/people/clearlink-team.jpeg'

import { VariableContent, Typography, Tabs } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'
import OneTrustPrivacyPolicy from '../components/OneTrustPrivacyPolicy'

const Home = () => {
  const tabData = [
    {
      title: 'Privacy Policy',
      content: <OneTrustPrivacyPolicy />,
    },
    {
      title: 'Transparency in Coverage',
      content: (
        <div>
          <Typography variant="h4">
            Consolidated Appropriations Act and Transparency in Coverage
          </Typography>
          <Typography>
            This link leads to the machine-readable files that are made
            available in response to the federal Transparency in Coverage Rule
            and includes negotiated service rates and out-of-network allowed
            amounts between health plans and healthcare providers. The
            machine-readable files are formatted to allow researchers,
            regulators, and application developers to access and analyze data
            more easily.
          </Typography>
        </div>
      ),
    },
  ]
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Learn More About Us, Our Team, & Our Values | Clearlink',
          description:
            'Meet our leadership team, learn about our commitment to inclusion and diversity, and discover the values that guide our decision-making on a daily basis.',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/privacy',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink | Privacy Policy',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <VariableContent
          backgroundColor="dark"
          mainContent={<Typography variant="h1">Privacy Policy</Typography>}
          alignMainContent="center"
        ></VariableContent>
        <VariableContent alignMainContent="center">
          <Tabs data={tabData} />
        </VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
